import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import secondaryImg from "../images/primary-coaches-sm.jpg"
import secondaryImgMob from "../images/primary-coaches-sm-mobile.jpg"
import secondaryImgWebp from "../images/primary-coaches-sm.webp"
import secondaryImgMobWebp from "../images/primary-coaches-sm-mobile.webp"
import coachServicesImg from "../images/coach-hire-services-sm.jpg"
import smileSvg from "../images/smile-beam-regular.svg"
import starSvg from "../images/star-solid.svg"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import NewsStrip from "../components/news-strip";
import AOS from "aos"
import 'aos/dist/aos.css';

class CoachHireWakefieldPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach Hire Wakefield" description="Established in 1949, Tetley's Coaches is one of Yorkshire's oldest coach companies and has provided safe and reliable coach transport in Wakefield and beyond for over 70 years." />
        <div className="landing-hero-wakefield">
          <div className="landing-hero__inner">
            <div className="landing-hero__inner__headline">
              <p className="landing-hero__inner__primary-headline">Reliable, affordable and local</p>
              <p className="landing-hero__inner__secondary-headline">Bus and coach hire since 1949</p>
            </div>
          </div>
        </div>

        <div className="block-about-us body-bg">
          <div className="body">
            <div className="block-about-us__container">
              <h1>Coach Hire Wakefield</h1>
              <h2>Providing Coach Hire for over 70 years</h2>
              <div className="gutter block-about-us__content-parent">
                <div className="block-about-us__content-left">
                  <picture className="block-about-us__hero">
                    <source media="(max-width: 499px)" type="image/webp" srcSet={secondaryImgMobWebp} />
                    <source media="(max-width: 499px)" type="image/jpg" srcSet={secondaryImgMob} />
                    <source media="(min-width: 500px)" type="image/webp" srcSet={secondaryImgWebp} />
                    <source media="(min-width: 500px)" type="image/jpg" srcSet={secondaryImg} />
                    <img src={secondaryImg} alt="Coach hire Wakefield" />
                  </picture>
                </div>
                <div className="block-about-us__content-right">
                  <p>Just a short journey south on the M1 from our yard in Leeds we have a fast-growing client base in the Wakefield area. We are proud to be delivering a safe, reliable and affordable transport service to the local schools, academies and sports clubs in the region.</p>
                  <p>Repeat business with customers such as Wakefield College, Wakefield girls’ High school and the supporters of Wakefield Trinity Wildcats reflects the high-quality service we have on offer.</p>
                </div>

              </div>
              <p style={{ textAlign: 'center' }}>
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="block-services">
          <div className="body gutter">
            <div className='block-services__heading' data-aos='fade-left' data-aos-duration="300">
              <h2>Coach hire quote</h2>
              <h3>Coach hire for any occasion</h3>
            </div>
            <div className="block-services__primary">

              <div className="block-services__primary-text">
                <p>Why not explore the city of Wakefield by visiting local attractions such as The Hepworth gallery, The Yorkshire Sculpture park or The National coal mining museum? Our friendly sales team can discuss your options, please do give us a call on 0113 276 2276.</p>
                <p>We also operate the Wakefield Free city bus. Working closely with Metro and Wakefield city council to provide free travel around the city centre in our fleet of modern Euro 6 compliant Solo Optare vehicles.</p>
                <p>Alternatively you can complete our <a href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">online quote form</a> for a speedy no-obligation emailed quote.</p>
              </div>

              <div className="block-services__primary-img">
                <img src={coachServicesImg} alt="" />
              </div>

            </div>

            <div className="block-services__info" data-aos="fade-right" data-aos-duration="600">
              <img className="block-services__info-icon" src={smileSvg} alt='' />
              <div className="block-services__info-text">
                <h4>Unbeaten value</h4>
                <p>If we provide a quote and you can find a similar quality coach cheaper we'll do our best to beat it.</p>
              </div>
            </div>
            <div id="services" className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/airport-transfers/" title="Coach Hire For Airport Transfers">Airport Transfers</Link></li>
                <li><Link to="/coach-hire-services/club-pub-trips/" title="Coach Hire For Club and Pub Trips">Club and Pub Trips</Link></li>
                <li><Link to="/coach-hire-services/coast-country-day-trips/" title="Coach Hire For Coast and Country Day Trips">Coast and Country Day Trips</Link></li>
                <li><Link to="/coach-hire-services/college-university-trips/" title="Coach Hire For College and University Trips">College and University Trips</Link></li>
                <li><Link to="/coach-hire-services/community-groups/" title="Coach Hire For Community Groups">Community Groups</Link></li>
                <li><Link to="/coach-hire-services/contract-coach-hire/" title="Coach Hire For Contract Work">Contract Work</Link></li>
                <li><Link to="/coach-hire-services/corporate-events-coach-hire/" title="Coach Hire For Corporate and Events">Corporate and Events</Link></li>
                <li><Link to="/coach-hire-services/day-at-the-races/" title="Coach Hire For A Day At The Races">Day At The Races</Link></li>
              </ul>
            </div>
            <div className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/wheelchair-disabled-access-coach-hire/" title="Wheelchair friendly and disabled access coach hire">Disabled access coach hire</Link></li>
                <li><Link to="/coach-hire-services/funerals/" title="Coach Hire For Funerals">Funerals</Link></li>
                <li><Link to="/coach-hire-services/group-tours/" title="Coach Hire For Group Tours">Group Tours</Link></li>
                <li><Link to="/coach-hire-services/school-trips/" title="Coach Hire For School Trips">School Trips</Link></li>
                <li><Link to="/coach-hire-services/sporting-events/" title="Coach Hire For Sporting Events">Sporting Events</Link></li>
                <li><Link to="/coach-hire-services/theatre-concerts-cultural-events/" title="Coach Hire For Theatre, Concerts &amp; Cultural Events">Theatre, Concerts &amp; Cultural Events</Link></li>
                <li><Link to="/coach-hire-services/theme-parks/" title="Coach Hire For Theme Parks">Theme Parks</Link></li>
                <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Weddings, Engagements, Parties and Nights Out</Link></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="block-review">
          <div className="body gutter">
            <h2>Reviews</h2>
            <div className="reviews">
              <div className="review" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">Can I just say a massive "thanks" to Dave one of the driver's on our visit to the national Coal Mining Museum.</p>
                <p className="review-quote">Staff and pupils said he was amazing <span role="img" aria-label="Smiley face">😊</span><span role="img" aria-label="Smiley face">😊</span></p>
                <p className="review-author">Sharon, Wakefield</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">Our Principal has asked that I pass on her gratitude to your driver who took our Reception children to Ledston. The class teacher commented on how lovely he was with the children and staff which made their day out all the more enjoyable.</p>
                <p className="review-quote">He couldn’t have been more helpful and friendly and we are very grateful.</p>
                <p className="review-author">Mrs Richmond, Wakefield</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">Just a line to thank you for your prompt and pleasant service last Saturday.</p>
                <p className="review-quote">The visit to Harewood House was greatly enjoyed by all and I'm grateful to you for your part in it.</p>
                <p className="review-quote">Many thanks and best wishes</p>
                <p className="review-author">James, Wakefield</p>
              </div>
            </div>
            <p style={{ margin: 0 }}>
              <a className='btn btn-review btn-grow' href="https://www.google.com/search?q=tetleys+coaches&ie=UTF-8#lrd=0x48795c38882c4475:0x9fc9a7dfa886a150,1,,," target="_blank" rel="noopener noreferrer">
                <span className='btn-review__text'>More reviews</span>
                <img className='btn-review__icon' src={arrowSvg} alt="" />
              </a>
            </p>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default CoachHireWakefieldPage
